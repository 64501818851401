<template>
    <h3 class="col-12 text-end mb-4 my-2 placeholder" v-if="$root.loadinger">
        قسم المواعيد
    </h3>
    <h3 class="col-12 text-end mb-4 my-2" v-else>
        قسم المواعيد
    </h3>
    <nav class="nav nav-pills nav-fill">
        <a class="nav-link active" aria-current="page" href="#">المواعيد ({{ limit }})</a>
        <router-link to="/done" class="nav-link">مواعيد مؤكدة</router-link>
        <router-link class="nav-link" to="/cancel">مواعيد ملغية</router-link>
    </nav>
    <br>
    <div class="alert alert-info border-0 p-2" v-if="$root.loadinger">
        جاري تحميل الصفحة...
    </div>
    <div v-else>
        <div class="alert alert-danger border-0 p-2" v-if="rows.length == 0">
            لاتوجد مواعيد
        </div>
        <Picker v-for="(val, int) in rows" :key="int" :data="val" />
    </div>
</template>

<script>
import axios from "axios";
import Picker from "../components/Picker.vue";

export default {
    name: 'thanks',
    components: {
        Picker
    },
    data() {
        return {
            id: 0,
            button: true,
            rows: [],
            limit: 0
        }
    },
    methods: {
        async run() {
            this.$root.alert = 0
            this.$root.alertcheck = []
            this.$root.loadinger = true
            let token = localStorage.getItem("api-auth");
            if (token != '' && token) {
                await axios.get(this.$root.url + 'api/auth/new', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then(async (response) => {
                        this.limit = response.data.limit
                        this.rows = response.data.rows
                        this.$root.loading()
                    })
                    .catch(() => {
                        this.$router.push("/")
                    })
            }
        }
    },
    mounted() {
        this.run();
    },
    watch: {
        '$root.tester': 'run'
    }
}
</script>