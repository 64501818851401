<template>
    <div></div>
</template>

<script>
export default {
    methods: {
        run() {
            this.$router.push("/")
        }
    },
    mounted() {
        this.run()
    }
}
</script>