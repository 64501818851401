<template>
    <div class="card border-0 shadow my-3" :id="'card' + data.id">
        <div class="card-header bg-body pb-0">
            <b>
                {{ data.service.title }} ({{ data.service.duration }} دقيقة)
            </b>
            <button class="btn float-start text-success" v-if="data.val == 1">
                <i class="bi bi-check2"></i>
                مؤكد
            </button>
            <button class="btn float-start text-danger" v-else-if="data.val == 2">
                <i class="bi bi-x-lg"></i>
                ملغى
            </button>
            <button class="btn float-start text-danger" data-bs-toggle="modal"
                :data-bs-target="'#exampleModal' + data.id" v-else>
                <i class="bi bi-trash"></i>
            </button>
            <button class="btn float-start text-dark" @click="editorx" v-if="data.val != 1 && data.val != 2">
                <i class="bi bi-pencil-square"></i>
            </button>
        </div>
        <div class="card-body border-0 pt-2" v-if="editor">
            <div class="d-flex justify-content-between">
                <div>
                    <i class="bi bi-clock text-warning ms-1"></i>
                    {{ data.dateis }}
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div>
                    <i class="bi bi-person text-warning ms-1"></i>
                    {{ data.present }}
                </div>
                <div>
                    <i class="bi bi-currency-dollar text-warning ms-1"></i>
                    {{ data.service.price }} ريال
                </div>
            </div>
        </div>
        <div class="card-body border-0 pt-2" v-else>
            <PickerEdit :data="data.service" :input="data" />
        </div>
    </div>

    <div class="modal fade" :id="'exampleModal' + data.id" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" v-if="data.val != 1 && data.val != 2">
        <div class="modal-dialog">
            <div class="modal-content rounded-0">
                <div class="modal-header bg-danger text-light rounded-0">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">هل انت متأكد ؟</h1>
                    <button type="button" class="btn-close me-auto ms-0" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    سيتم الغاء الحجز ولكن بأمكانك الحجز مرة آخرى
                </div>
                <div class="modal-footer rounded-0">
                    <button type="button" class="btn btn-secondary rounded-0" data-bs-dismiss="modal">إغلاق
                        النافذة</button>
                    <button type="button" class="btn btn-danger rounded-0" @click="deletex">تأكيد الغاء الحجز</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';
import PickerEdit from '@/components/PickerEdit.vue'
export default {
    name: 'Picker',
    data() {
        return {
            editor: true
        }
    },
    props: {
        data: Array
    },
    components: {
        PickerEdit
    },
    methods: {
        editorx() {
            this.$root.editor = this.data.id
            if (this.editor) {
                this.editor = false
            }
            else {
                this.editor = true
            }
        },
        xcheck() {
            if (this.$root.editor != this.data.id) {
                this.editor = true
            }
        },
        async deletex() {
            let id = this.data.id;
            this.$root.loadinger = true
            $("#exampleModal" + id + " button.btn-close").click()
            let token = localStorage.getItem("api-auth");
            if (token != '' && token) {
                axios.post(this.$root.url + 'api/deletepickers', {
                    id: id
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then(async (response) => {
                        response.data
                        this.$root.tester = Math.floor(Math.random() * 100)
                    })
            }
        }
    },
    watch: {
        '$root.editor': 'xcheck'
    }
}
</script>