<template>
    <div class="overlayPanel">
        <div class="container">
            <div class="align-items-center row" v-if="$root.loadinger">
                <div class="ServicesSummary-btn col-auto d-flex col">
                    <div class="d-flex flex-column">
                        <p class="ServicesSummary_total mb-0 placeholder d-block mb-2" style="width: 56px;"></p>
                        <div class="d-flex flex-column flex-md-row">
                            <p class="services-summary-total-amount mb-0 fw-bold fs-5 placeholder" style="width: 76px;"></p>
                        </div>
                    </div>
                </div>
                <div class="ServicesSummary-btn ar-mode col text-start">
                    <button type="button" disabled class="undefined btn-dark btn btn-primary placeholder" 
                    style="width: 86px;">
                    </button>
                </div>
            </div>
            <div class="align-items-center row" v-else>
                <div class="ServicesSummary-btn col-auto d-flex col">
                    <div class="d-flex flex-column">
                        <p class="ServicesSummary_total mb-0">المجموع:</p>
                        <div class="d-flex flex-column flex-md-row">
                            <p class="services-summary-total-amount mb-0 fw-bold fs-5">{{ $root.xprice }} ريال</p>
                        </div>
                    </div>
                </div>
                <div class="ServicesSummary-btn ar-mode col text-start">
                    <button class="undefined btn-dark btn btn-primary" disabled v-if="$root.cselect.length == 0">
                        اختر الوقت
                    </button>
                    <router-link to="/booking" class="undefined btn-dark btn btn-primary" v-else>
                        اختر الوقت
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
