<template>
  <div class="position-relative my-2 border-bottom" v-if="$root.loadinger">
    <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="checkbox" disabled>
    <label class="list-group-item py-3 pe-5 rounded-0">
      <span class="amount">
        <span class="placeholder" style="width: 46px;"></span>
      </span>
      <strong class="fw-semibold">
        <span class="placeholder mb-2" style="width: 86px;"></span>
      </strong>
      <span class="d-block small opacity-75">
        <span class="placeholder d-block" style="width: 296px;"></span>
      </span>
    </label>
  </div>
  <div class="position-relative my-2 border-bottom" v-else>
    <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="checkbox" name="service"
      :id="'service' + data.id" :value="data.id" v-model="radio">
    <label class="list-group-item py-3 pe-5 rounded-0" :for="'service' + data.id">
      <span class="amount">
        {{ data.price }} ريال
      </span>
      <strong class="fw-semibold">
        {{ data.title }} <b>({{ data.duration }} دقيقة)</b>
      </strong>
      <span class="d-block small opacity-75">
        {{ data.description }}
      </span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'ListGroup',
  props: {
    data: Array,
  },
  data() {
    return {
      radio: true,
      cselect: []
    }
  },
  methods: {
    run() {
      this.cselect = this.$root.cselect
      this.recheck()
      setTimeout(()=>{
        this.recheck()
      }, 200)
    },
    xcheck() {
      let rid = this.radio;
      let xid = this.data.id;
      let arr = this.$root.cselect;
      if (rid && this.vvcheck(xid)) {
        this.$root.cselect.push(xid);
        this.$root.rows.push(this.data)
        this.xprice()
      } else if (!rid) {
        let vro = this.$root.rows.filter(this.checkRows)
        let tid = arr.filter(this.checkAdult)
        this.$root.cselect = tid;
        this.$root.rows = vro
        this.xprice()
      }
    },
    checkRows(num) {
      let xid = this.data.id;
      return num.id != xid;
    },
    checkAdult(num) {
      let xid = this.data.id;
      return num != xid;
    },
    vvcheck(id) {
      let raws = this.$root.cselect;
      for (let index = 0; index < raws.length; index++) {
        if (raws[index] == id) return false
      }
      return true
    },
    recheck() {
      let id = this.data.id
      let raws = this.$root.cselect;
      for (let index = 0; index < raws.length; index++) {
        if (raws[index] == id) {
          this.radio = true
          return;
        }
      }
      this.radio = false
      return;
    },
    xprice() {
      let price = 0;
      let rows = this.$root.rows;
      for (let index = 0; index < rows.length; index++) {
        price = Number(Number(rows[index].price) + Number(price))
      }
      this.$root.price = price
    }
  },
  mounted() {
    this.run()
  },
  watch: {
    '$root.cselect': 'recheck',
    radio: 'xcheck',
  }
}
</script>