<template>
    <div class="position-relative my-2 border-bottom" v-if="$root.loadinger">
        <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="checkbox" disabled>
        <label class="list-group-item py-3 pe-5 rounded-0">
            <span class="amount">
                <span class="placeholder" style="width: 46px;"></span>
            </span>
            <strong class="fw-semibold">
                <span class="placeholder mb-2" style="width: 86px;"></span>
            </strong>
            <span class="d-block small opacity-75">
                <span class="placeholder d-block" style="width: 296px;"></span>
            </span>
        </label>
    </div>
    <div v-else>
        <div class="alert alert-danger border-0 py-2" v-if="$root.alert == data.id">
            <i class="bi bi-info-circle"></i>
            يوجد موعد بنفس التوقيت , قم بأختيار وقت آخر
        </div>
        <div class="card border-0 shadow my-3" v-if="vleader">
            <div class="card-header bg-body pb-0">
                <b>
                    {{ data.title }} ({{ data.duration }} دقيقة)
                </b>
                <button class="btn float-start text-danger" @click="xdelete">
                    <i class="bi bi-trash"></i>
                </button>
                <button class="btn float-start" @click="checkedcard">
                    <i class="bi bi-pencil-square"></i>
                </button>
            </div>
            <div class="card-body border-0 pt-2">
                <div class="d-flex justify-content-between">
                    <div>
                        <i class="bi bi-person text-warning ms-1"></i>
                        {{ searchpresenters(vpresenter) }}
                    </div>
                    <div>
                        <i class="bi bi-clock text-warning ms-1"></i>
                        {{ searchtimes(vtime) }}
                    </div>
                    <div>
                        <i class="bi bi-clock-history text-warning ms-1"></i>
                        {{ data.duration }} دقيقة
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0" v-else>
            <div class="card-header border-0 bg-body pb-0">
                حدد فريق العمل والوقت
                <b>
                    {{ data.title }}
                    ({{ data.duration }} دقيقة)
                </b>
                <button class="btn float-start" @click="xdelete">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
            <div class="card-body border-0 pt-0">
                <div class="row">
                    <div v-for="(val, int) in data.presenters" :key="int" class="col-2 col-md-1 ms-3 text-center">
                        <input class="list-group-item-check pe-none" type="radio" :name="'presenter' + data.id"
                            :id="data.id + 'presenter' + int" :value="val.id" v-model="vpresenter">
                        <label class="list-group-item rounded-3 py-3 widthover w-100" style="width: 95px;"
                            :for="data.id + 'presenter' + int">
                            <img src="@/assets/profile.webp" class="d-block m-auto mb-1" width="50">
                            <small class="bloder">{{ val.title }}</small>
                        </label>
                    </div>
                </div>
                <div class="row mt-2 position-relative" v-if="vpresenter">
                    <div class="bg-black bg-opacity-75 h-100 loadinger position-absolute w-100 z-3" v-if="loadinger">
                        <div class="spinner-border text-light m-auto position-absolute end-0 start-0"
                            style="top: 0;bottom: 0;" role="status">
                        </div>
                    </div>
                    <div class="alert alert-info" v-if="toHouers.length == 0">
                        لاتوجد أوقات متوفرة
                    </div>
                    <div v-for="(vbl, nbm) in toHouers" :key="nbm"
                        :class="'col-3 col-md-2 text-center p-0 px-1' + (ClassNone(vbl.id) && vbl.has == 'has' ? ' d-none' : (filterApresenter(vbl.id) ? ' d-none' : ''))">
                        <input
                            :class="'list-group-item-check pe-none' + (ClassDate(vbl.id) || ClassDark(vbl.id) ? ' disabled bg-danger-subtle' : '')"
                            type="radio" :name="'timer' + data.id + vpresenter"
                            :id="nbm + 'time' + data.id + 'r' + vbl.id" :value="vbl.id" v-model="vtime">
                        <label
                            :class="'list-group-item rounded-3 my-1 py-1 widthover border border-2' + (ClassDate(vbl.id) || ClassDark(vbl.id) ? ' disabled bg-danger-subtle' : '')"
                            :for="nbm + 'time' + data.id + 'r' + vbl.id" style="width: 100% !important;">
                            <span class="d-block small text-danger bg-danger-subtle text-decoration-line-through"
                                v-if="ClassDate(vbl.id)">
                                {{ vbl.p }} {{ vbl.time }}
                            </span>
                            <span class="d-block small text-danger bg-danger-subtle text-decoration-line-through"
                                v-else-if="ClassDark(vbl.id)">
                                {{ vbl.p }} {{ vbl.time }}
                            </span>
                            <span class="d-block small" v-else>{{ vbl.p }} {{ vbl.time }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
import days from '@/assets/days.json'
import axios from "axios";

export default {
    props: {
        ndate: String,
        vdate: String,
        data: Array
    },
    data() {
        return {
            loadinger: false,
            ctimes: [],
            houers: days,
            addHouers: [],
            toHouers: [],
            fake: 0,
            vtime: null,
            vpresenter: null,
            vleader: null,
            presenter: []
        }
    },
    methods: {
        checknon() {
            let select = this.$root.cselect.length
            let cards = this.$root.cards.length
            if (select == cards) {
                this.$root.vleader = true
            }
            else {
                this.$root.vleader = false
            }
        },
        async checkhouers() {
            if (this.vpresenter) {
                let houer = days
                houer = houer.filter(this.checkhouer)
                houer = houer.filter(this.checkPresent)
                if (this.ndate == this.vdate) {
                    houer = houer.filter(this.checkIsnowDay)
                }
                houer = this.filterHouers(houer)
                await this.checkdate()
                this.houers = houer
                let addHouers = this.objHouers()
                addHouers = addHouers.filter(this.filterAhouer)
                this.addHouers = addHouers.filter(this.filterApresenter)
                let toHouers = this.filterToHouer()
                this.toHouers = toHouers
                this.toHouers = await toHouers.sort((a, b) => a.short.localeCompare(b.short))
                this.toHouers = toHouers.filter(this.filterApresenter)
                this.toHouers = toHouers.filter(this.filterCopy)
                if (this.ndate == this.vdate) {
                    this.toHouers = toHouers.filter(this.checkMinnowDay)
                }
            }
        },
        checkIsnowDay(num) {
            let limt = new Date()
            limt = limt.getHours()
            if (num.id > limt) {
                return true
            }
            return false
        },
        checkMinnowDay(num) {
            let limt = new Date()
            limt = limt.getHours()
            let id = this.tkId(num.id)
            if (id > limt) {
                return true
            }
            return false
        },
        filterCopy(num) {
            let houers = this.toHouers
            let e = 0
            let x = this.fake
            for (let index = 0; index < houers.length; index++) {
                if (houers[index].id == num.id) {
                    e++;
                    if (e > 1) {
                        this.fake = (x + 1)
                        if (x > 0) {
                            this.fake = 0
                            return false
                        }
                    }
                }
            }
            return true
        },
        filterToHouer() {
            let houer = this.houers
            let adder = this.addHouers
            for (let index = 0; index < adder.length; index++) {
                houer.push(adder[index])
            }
            return houer
        },
        // funs
        filterAhouer(num) {
            let houers = this.data.timer
            let id = this.tkId(num.id)
            for (let houerx = 0; houerx < houers.length; houerx++) {
                if (houers[houerx] == id) {
                    return true
                }
            }
            return false
        },
        filterApresenter(num) {
            let presenters = this.data.presenters
            presenters = presenters[0].timer
            let id = this.tkId(num.id)
            for (let presentx = 0; presentx < presenters.length; presentx++) {
                if (presenters[presentx] == id) {
                    return true
                }
            }
            return false
        },
        filterSort(id) {
            let time = String(id)
            let toxs = String(time)
            let text = ''
            let tink = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
            if (time.length == 1) {
                text = String(toxs + '000')
            }
            else if (time.length == 2) {
                text = String('Z' + toxs + '0')
            }
            else if (time.length == 3) {
                text = String(toxs + '0')
            } else {
                text = String('Z' + toxs)
            }
            for (let index = 0; index < text.length; index++) {
                for (let inter = 0; inter < tink.length; inter++) {
                    if (text[index] == inter) {
                        text = text.replace(text[index], tink[inter])
                    }
                }
            }

            return text
        },
        filterHouers(arr) {
            let array = arr
            let datepicker = this.data.datepicker
            let duration = this.data.duration
            let houers = []
            datepicker = (Math.floor(60 / duration))
            if (duration <= 30 || duration >= 60) {
                for (let index = 0; index < array.length; index++) {
                    for (let intex = 0; intex < (datepicker ? datepicker : 1); intex++) {
                        houers.push({
                            "has": "is",
                            "short": this.filterSort(intex ? (array[index].id + '' + (intex ? (duration * intex) : '')) : array[index].id),
                            "id": (intex ? (array[index].id + '' + (intex ? (duration * intex) : '')) : array[index].id),
                            "time": (array[index].time).replace('00', (intex ? (duration * intex) : '00')),
                            "p": array[index].p
                        })
                    }
                }
            } else if (duration <= 59) {
                let obj = []
                let fake = []
                let tost = 0
                let swit = 0
                for (let index = 0; index < array.length; index++) {
                    swit = tost
                    obj = this.restFiltertik(duration, swit)
                    if (obj.num > 1) {
                        tost = (tost + 1)
                    }
                    for (let inter = 0; inter < obj.num; inter++) {
                        fake = (inter ? this.restFiltertik(duration, (swit + inter)) : obj)
                        houers.push({
                            "has": "is",
                            "short": this.filterSort(array[index].id + '' + (fake.min && fake.min != '00' ? fake.min : '')),
                            "id": (array[index].id + '' + (fake.min && fake.min != '00' ? fake.min : '')),
                            "time": (array[index].time).replace('00', (fake.min ? fake.min : '00')),
                            "p": array[index].p
                        })
                    }
                    if ((index + 1) < array.length && (array[index].id + 1) != array[(index + 1)].id) {
                        tost = 0
                    } else {
                        tost = (tost + 1)
                    }
                }
            }
            return houers
        },
        restFiltertik(m, index) {
            let mint = (index == 0 ? 0 : (m * index))
            let test = (Math.floor(mint / 60))
            let mintr = (mint > 60 ? (mint - (60 * test)) : mint)
            let tost = (mintr + Number(m))
            let num = ((60 > tost) ? 2 : 1)
            return {
                "min": (mintr < 10 ? (0 + '' + mintr) : mintr),
                "num": num
            }
        },
        async checkdate() {
            if (this.vpresenter) {
                let xdate = this.vdate
                let presenter = this.presenter[0].token;
                this.ctimes = []
                this.loadinger = true
                this.vtime = null
                this.vleader = null
                await axios
                    .get(this.$root.url + "api/checkpickers?date=" + xdate + "&presenter=" + presenter)
                    .then(async (response) => {
                        this.ctimes = response.data.data
                        this.loadinger = false
                    })
            }
        },
        objHouers() {
            let array = days
            let cards = this.$root.cards
            let presenter = this.vpresenter
            let houers = []
            let objfake = []
            let filter = []

            if (cards.length != 0) {
                for (let index = 0; index < array.length; index++) {
                    for (let inter = 0; inter < cards.length; inter++) {
                        objfake = this.tkObjc(cards[inter])
                        if (objfake.id == array[index].id) {
                            if (cards[inter].presenter == presenter) {
                                filter = this.filterObjHouer(objfake.id, objfake.min, cards[inter].duration)
                                if (filter.id) {
                                    houers.push({
                                        "has": "has",
                                        "short": this.filterSort(filter.id),
                                        "id": (filter.id),
                                        "time": (filter.houer + ':00').replace('00', (filter.min ? filter.min : '00')),
                                        "p": filter.p
                                    })
                                }
                            }
                        }
                    }
                }
            }
            return this.objHouers2(houers)
        },
        objHouers2(xarray) {
            let array = days
            let cards = this.ctimes
            let houers = xarray
            let objfake = []
            let filter = []

            if (cards.length != 0) {
                for (let index = 0; index < array.length; index++) {
                    for (let inter = 0; inter < cards.length; inter++) {
                        objfake = this.tkDef(cards[inter])
                        if (objfake.id == array[index].id) {
                            filter = this.filterObjHouer(objfake.id, objfake.min, cards[inter].duration)
                            if (filter.min != 0) {
                                houers.push({
                                    "has": "has",
                                    "short": this.filterSort(filter.id),
                                    "id": (filter.id),
                                    "time": (filter.houer + ':00').replace('00', (filter.min ? filter.min : '00')),
                                    "p": filter.p
                                })
                            }
                        }
                    }
                }
            }
            return houers
        },
        filterObjHouer(id, minr, duration) {
            let mint = Number(Number(minr) + Number(duration))
            let test = Number(Math.floor(mint / 60))
            let mintr = (mint > 59 ? (mint - (60 * test)) : mint)
            let bid = Number(id)
            let houer = Number(this.rrrhouer(bid + test))
            let xvd = Number(Number(id) + Number(test))
            let xid = Number(xvd + '' + (mintr ? mintr : ''))
            let p = (xvd < 12 ? 'ص' : (xvd < 24 ? 'م' : 'ص'))
            return {
                "id": xid,
                "houer": houer,
                "min": mintr,
                "p": p
            }
        },
        rrrhouer(id) {
            if (id < 12 || id == 12) {
                return id
            }
            else if (id < 24 || id == 24) {
                return (id - 12)
            }
            else {
                return (id - 24)
            }

        },
        checkObj(num) {
            if (this.ClassDate(num.id)) {
                return true
            }
            else if (this.ClassDark(num.id)) {
                return true
            }
            return false
        },
        // Obj
        tkObjc(obj) {
            let time = String(obj.time)
            let tims = 0
            let houer = 0
            let min = 0
            let p = ''

            if (time.length < 2) {
                houer = time
                tims = time
                p = 'ص'
            }
            else if (time.length < 3 && Number(time[0] + '' + time[1]) > 24) {
                tims = time
                houer = (Number(time) - 24)
                min = (time[1] + '' + time[2])
                p = 'ص'
            }
            else if (time.length < 3 && Number(time[0] + '' + time[1]) < 25) {
                tims = time
                houer = (Number(time) - 12)
                p = 'م'
            }
            else if (time.length < 4) {
                houer = time[0]
                tims = houer
                min = (time[1] + '' + time[2])
                p = 'ص'
            }
            else {
                houer = Number(time[0] + '' + time[1])
                tims = houer
                houer = (Number(houer) - (houer < 25 ? 12 : 24))
                min = (time[2] + '' + time[3])
                p = 'م'
            }
            return {
                'id': Number(tims),
                'p': p,
                'houer': Number(houer),
                'min': Number(min),
                'presenter': obj.presenter,
                'time': (houer + ':00'),
                'length': time.length,
                'duration': obj.duration
            }
        },
        tkDef(obj) {
            let time = String(obj.datetime)
            let tims = 0
            let houer = 0
            let min = 0
            let p = ''

            if (time.length < 2) {
                houer = time
                tims = time
                p = 'ص'
            }
            else if (time.length < 3 && Number(time[0] + '' + time[1]) > 24) {
                tims = time
                houer = (Number(time) - 24)
                min = (time[1] + '' + time[2])
                p = 'ص'
            }
            else if (time.length < 3 && Number(time[0] + '' + time[1]) < 25) {
                tims = time
                houer = (Number(time) - 12)
                p = 'م'
            }
            else if (time.length < 4) {
                houer = time[0]
                tims = houer
                min = (time[1] + '' + time[2])
                p = 'ص'
            }
            else {
                houer = Number(time[0] + '' + time[1])
                tims = houer
                houer = (Number(houer) - (houer < 25 ? 12 : 24))
                min = (time[2] + '' + time[3])
                p = 'م'
            }
            return {
                'id': Number(tims),
                'p': p,
                'houer': Number(houer),
                'min': Number(min),
                'time': (houer + ':00'),
                'length': time.length,
                'duration': obj.duration
            }
        },
        tkId(obj) {
            let time = String(obj)
            let houer = 0
            if (time.length == 3) {
                houer = time[0]
            }
            else if (time.length == 4) {
                houer = (time[0] + '' + time[1])
            } else {
                houer = time
            }
            return houer
        },
        tkMin(obj) {
            let time = String(obj)
            let min = 0
            if (Number(time.length) == 3) {
                min = Number(time[1] + '' + time[2])
            }
            else if (Number(time.length) == 4) {
                min = Number(time[2] + '' + time[3])
            }
            return min
        },
        // endobj
        xdelete() {
            this.vpresenter = null
            let arr = this.$root.cselect;
            let vro = this.$root.rows.filter(this.restRows)
            let tid = arr.filter(this.restData)
            let cad = this.$root.cards.filter(this.restCards)
            this.$root.cards = cad
            this.$root.cselect = tid;
            this.$root.rows = vro
            this.xprice()
            alert("تم أزالة الخدمة المختارة")
        },
        rstxdelete() {
            this.vpresenter = null
            let arr = this.$root.cselect;
            let vro = this.$root.rows.filter(this.restRows)
            let tid = arr.filter(this.restData)
            let cad = this.$root.cards.filter(this.restCards)
            this.$root.cards = cad
            this.$root.cselect = tid;
            this.$root.rows = vro
            this.xprice()
        },
        xprice() {
            let price = 0;
            let rows = this.$root.rows;
            for (let index = 0; index < rows.length; index++) {
                price = Number(Number(rows[index].price) + Number(price))
            }
            this.$root.price = price
        },
        searchpresenters(val) {
            let pre = this.data.presenters
            for (let index = 0; index < pre.length; index++) {
                if (pre[index].id == val) {
                    return pre[index].title
                }
            }
            return '';
        },
        searchtimes(val) {
            let hor = this.houers
            let ahr = this.addHouers
            for (let index = 0; index < hor.length; index++) {
                if (hor[index].id == val) {
                    return hor[index].p + ' ' + hor[index].time
                }
            }
            for (let index = 0; index < ahr.length; index++) {
                if (ahr[index].id == val) {
                    return ahr[index].p + ' ' + ahr[index].time
                }
            }
            return '';
        },
        // filter
        // end filter
        ClassDark(id) {
            let array = this.ctimes
            let duration = 0
            let fake = []
            for (let index = 0; index < array.length; index++) {
                duration = Number(Math.floor(array[index].duration / 60))
                if (duration > 1) {
                    for (let inter = 1; inter < duration; inter++) {
                        fake = this.tkDef(array[index])
                        if (Number(fake.id) == Number(Number(id) - Number(inter))) {
                            return true
                        }
                    }
                }
            }
            return this.checkDarkTest(id)
        },
        checkDarkTest(id) {
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let duration = 0
            let fake = []
            for (let index = 0; index < nums.length; index++) {
                duration = Number(Math.floor(nums[index].duration / 60))
                if (duration > 1) {
                    for (let ind = 1; ind < duration; ind++) {
                        fake = this.tkObjc(nums[index])
                        if (Number(fake.id) == Number(Number(id) - Number(ind)) && presenter == nums[index].presenter) {
                            return true
                        }
                    }
                }
            }
            return this.checkDarkTest2(id)
        },
        checkDarkTest2(id) {
            let nums = this.ctimes
            let duration = 0
            let fake = []
            for (let index = 0; index < nums.length; index++) {
                duration = Number(Math.floor(nums[index].duration / 60))
                if (duration > 1) {
                    for (let ind = 1; ind < duration; ind++) {
                        fake = this.tkDef(nums[index])
                        if (Number(fake.id) == Number(Number(id) - Number(ind))) {
                            return true
                        }
                    }
                }
            }
            return this.checkDarkOther(id)
        },
        checkDarkOther(id) {
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let duration = 0
            let fake = []
            let fakx = []
            for (let index = 0; index < nums.length; index++) {
                duration = Number(Math.floor(nums[index].duration / 60))
                fakx = this.tkId(id)
                fake = this.tkObjc(nums[index])
                if (presenter == nums[index].presenter && (duration > 0.7) && this.data.duration > 59) {
                    if (fake.id == fakx) {
                        return true
                    }
                }
            }
            return this.checkDarkOther2(id)
        },
        checkDarkOther2(id) {
            let nums = this.ctimes
            let duration = 0
            let fake = []
            let fakx = []
            for (let index = 0; index < nums.length; index++) {
                duration = Number(Math.floor(nums[index].duration / 60))
                fakx = this.tkId(id)
                fake = this.tkDef(nums[index])
                if ((duration > 0.7) && this.data.duration > 59) {
                    if (fake.id == fakx) {
                        return true
                    }
                }
            }
            return this.checkDarkOtherTow(id)
        },
        checkDarkOtherTow(id) {
            let xid = id
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                if (presenter == nums[index].presenter) {
                    fakecard = this.tkObjc(nums[index])
                    fakethisid = this.tkId(xid)
                    if (Number(fakecard.min) < (Number(this.tkMin(xid)) + Number(this.data.duration))) {
                        if (fakecard.id == fakethisid && (Number(fakecard.min) + Number(fakecard.duration)) > (Number(this.tkMin(xid)))) {
                            return true
                        }
                    }
                }
            }
            return this.checkDarkOtherTow2(id)
        },
        checkDarkOtherTow2(id) {
            let xid = id
            let nums = this.ctimes
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                fakecard = this.tkDef(nums[index])
                fakethisid = this.tkId(xid)
                if (Number(fakecard.min) < (Number(this.tkMin(xid)) + Number(this.data.duration))) {
                    if (fakecard.id == fakethisid && (Number(fakecard.min) + Number(fakecard.duration)) > (Number(this.tkMin(xid)))) {
                        return true
                    }
                }
            }
            return this.checkDarkOtherThree(id)
        },
        checkDarkOtherThree(id) {
            let xid = id
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let fakecard = []
            let fakethisid = 0
            let faketime = 0
            for (let index = 0; index < nums.length; index++) {
                if (presenter == nums[index].presenter) {
                    fakecard = this.tkDef(nums[index])
                    fakethisid = this.tkId(xid)
                    faketime = Number(Number(xid) < 99 ? 0 : (Number(xid) < 999 ? (xid[1] + '' + xid[2]) : (xid[2] + '' + xid[3])))
                    if (fakecard.id == fakethisid && faketime > (fakecard.min + nums[index].duration) && faketime > fakecard.min) {
                        return true
                    }
                }
            }
            return this.checkDarkOtherThree2(id)
        },
        checkDarkOtherThree2(id) {
            let xid = id
            let nums = this.ctimes
            let fakecard = []
            let fakethisid = 0
            let faketime = 0
            for (let index = 0; index < nums.length; index++) {
                fakecard = this.tkObjc(nums[index])
                fakethisid = this.tkId(xid)
                faketime = Number(Number(xid) < 99 ? 0 : (Number(xid) < 999 ? (xid[1] + '' + xid[2]) : (xid[2] + '' + xid[3])))
                if (fakecard.id == fakethisid && faketime > (fakecard.min + nums[index].duration) && faketime > fakecard.min) {
                    return true
                }
            }
            return this.checkDarkOtherFore(id)
        },
        checkDarkOtherFore(id) {
            let xid = Number(id)
            let nums = this.ctimes
            let fakecard = 0
            for (let index = 0; index < nums.length; index++) {
                fakecard = Number(nums[index].datetime < 99 ? (nums[index].datetime + '' + 0 + '' + 0) : nums[index].datetime)
                if (fakecard < xid) {
                    if (xid < Number(fakecard + Number(nums[index].duration))) {
                        return true
                    }
                }
            }
            return this.checkDarkOtherFore2(id)
        },
        checkDarkOtherFore2(id) {
            let xid = Number(id)
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let fakecard = 0
            for (let index = 0; index < nums.length; index++) {
                fakecard = Number(nums[index].time < 99 ? (nums[index].time + '' + 0 + '' + 0) : nums[index].time)
                if (presenter == nums[index].presenter && fakecard < xid) {
                    if (xid < Number(fakecard + Number(nums[index].duration))) {
                        return true
                    }
                }
            }
            return this.checkPlus(id)
        },
        checkPlus(id) {
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                for (let ind = 0; ind < (Math.floor(nums[index].duration / 60)); ind++) {
                    if ((Math.floor(nums[index].duration / 60)) > 1 && presenter == nums[index].presenter) {
                        fakecard = this.tkDef(nums[index])
                        fakethisid = Number(this.tkId(id))
                        if (Number(fakecard.id) == Number(fakethisid - ind)) {
                            return true
                        }
                    }
                }
            }
            return this.checkPlus2(id)
        },
        checkPlus2(id) {
            let nums = this.ctimes
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                for (let ind = 0; ind < (Math.floor(nums[index].duration / 60)); ind++) {
                    if ((Math.floor(nums[index].duration / 60)) > 1) {
                        fakecard = this.tkObjc(nums[index])
                        fakethisid = Number(this.tkId(id))
                        if (Number(fakecard.id) == Number(fakethisid - ind)) {
                            return true
                        }
                    }
                }
            }
            return this.checkPoper(id)
        },
        checkPoper(id) {
            let nums = this.ctimes
            let duration = Number(this.data.duration)
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                if ((Math.floor(duration / 60)) > 1) {
                    fakecard = this.tkDef(nums[index])
                    fakethisid = Number(this.tkId(id))
                    if (Number(fakecard.id) == Number(fakethisid + 1)) {
                        return true
                    }
                }
            }
            return this.checkPoper2(id)
        },
        checkPoper2(id) {
            let nums = this.$root.cards
            let duration = Number(this.data.duration)
            let presenter = this.vpresenter
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                if (presenter == nums[index].presenter && (Math.floor(duration / 60)) > 1) {
                    fakecard = this.tkObjc(nums[index])
                    fakethisid = Number(this.tkId(id))
                    if (Number(fakecard.id) == Number(fakethisid + 1)) {
                        return true
                    }
                }
            }
            return this.checkPoper3(id)
        },
        checkPoper3(id) {
            let xmint = Number(this.tkMin(id))
            let nums = this.ctimes
            let duration = Number(Number(this.data.duration) + xmint)
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                if ((Math.floor(duration / 60)) > 0 && duration != 60) {
                    fakecard = this.tkDef(nums[index])
                    fakethisid = Number(this.tkId(id))
                    if (Number(fakecard.id) == Number(fakethisid + 1)) {
                        xmint = Number(this.tkMin(nums[index].datetime))
                        for (let loop = 0; loop < (Math.floor(duration / 60) + 1); loop++) {
                            if (Number(fakecard.id) == Number(Number(fakethisid) + Number(loop))) {
                                if (loop == 0 && Number(xmint) > (Number(duration) - Number(60 * Number(loop)))) {
                                    return true
                                }
                                else if (this.data.duration < 60 && loop != 0 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                    return true
                                }
                                else if (this.data.duration > 59 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                    return true
                                }
                            }
                        }
                    }
                }
            }
            return this.checkPoper4(id)
        },
        checkPoper4(id) {
            let xmint = Number(this.tkMin(id))
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let duration = Number(Number(this.data.duration) + xmint)
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                if (presenter == nums[index].presenter && (Math.floor(duration / 60)) > 0 && duration != 60) {
                    fakecard = this.tkObjc(nums[index])
                    fakethisid = Number(this.tkId(id))
                    if (Number(fakecard.id) == Number(fakethisid + 1)) {
                        xmint = Number(this.tkMin(nums[index].time))
                        for (let loop = 0; loop < (Math.floor(duration / 60) + 1); loop++) {
                            if (Number(fakecard.id) == Number(Number(fakethisid) + Number(loop))) {
                                if (loop == 0 && Number(xmint) > (Number(duration) - Number(60 * Number(loop)))) {
                                    return true
                                }
                                else if (this.data.duration < 60 && loop != 0 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                    return true
                                }
                                else if (this.data.duration > 59 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                    return true
                                }
                            }
                        }
                    }
                }
            }
            return this.checkPoper5(id)
        },
        checkPoper5(id) {
            let xmint = Number(this.tkMin(id))
            let nums = this.$root.cards
            let presenter = this.vpresenter
            let duration = 0
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                fakecard = this.tkObjc(nums[index])
                fakethisid = Number(this.tkId(id))
                duration = Number(Number(nums[index].duration) + Number(fakecard.min))
                if (presenter == nums[index].presenter && (Math.floor(duration / 60)) > 0) {
                    for (let loop = 0; loop < (Math.floor(duration / 60) + 1); loop++) {
                        if (Number(fakecard.id) == Number(Number(fakethisid) - Number(loop))) {
                            if (loop == 0 && Number(xmint) > (Number(duration) - Number(60 * Number(loop)))) {
                                return true
                            }
                            else if (loop != 0 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                return true
                            }
                            else if (this.data.duration > 59 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                return true
                            }
                        }
                    }
                }
            }
            return this.checkPoper6(id)
        },
        checkPoper6(id) {
            let xmint = Number(this.tkMin(id))
            let nums = this.ctimes
            let duration = 0
            let fakecard = []
            let fakethisid = 0
            for (let index = 0; index < nums.length; index++) {
                fakecard = this.tkDef(nums[index])
                fakethisid = Number(this.tkId(id))
                duration = Number(Number(nums[index].duration) + Number(fakecard.min))
                if ((Math.floor(duration / 60)) > 0) {
                    for (let loop = 0; loop < (Math.floor(duration / 60) + 1); loop++) {
                        if (Number(fakecard.id) == Number(Number(fakethisid) - Number(loop))) {
                            if (loop == 0 && Number(xmint) > (Number(duration) - Number(60 * Number(loop)))) {
                                return true
                            }
                            else if (loop != 0 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                return true
                            }
                            else if (this.data.duration > 59 && Number(xmint) < (Number(duration) - Number(60 * Number(loop)))) {
                                return true
                            }
                        }
                    }
                }
            }
            return this.breakcheck(id)
        },
        // البريك
        breakcheck(id) {
            let timer = []
            let fakethisid = Number(this.tkId(id))
            let xmint = Number(Number(this.tkMin(id)) + Number(this.data.duration))
            let presenters = this.data.presenters
            for (let inter = 0; inter < presenters.length; inter++) {
                timer = presenters[inter].timer
                if (this.vpresenter == presenters[inter].id) {
                    for (let index = 0; index < timer.length; index++) {
                        if (this.data.duration > 60 || xmint > 60) {
                            if (Number(timer[index]) != (Number(timer[(index + 1)]) - 1)) {
                                if (Number(timer[index]) == fakethisid) {
                                    return true
                                }
                            }
                        }
                    }
                }
            }
            return false
        },
        ClassDate(num) {
            let array = this.ctimes
            for (let index = 0; index < array.length; index++) {
                if (array[index].datetime == num) {
                    return true
                }
            }
            return false
        },
        ClassNone(num) {
            if (this.ClassDate(num) || this.ClassDark(num)) {
                return true
            }
            return false
        },
        checkhouer(num) {
            let array = this.data.timer
            for (let index = 0; index < array.length; index++) {
                if (array[index] == num.id) {
                    return true
                }
            }
            return false
        },
        restObj(num) {
            let array = this.addHouers
            let objOrg = this.tkObjc(num)
            let objfake = []
            for (let index = 0; index < array.length; index++) {
                objfake = this.tkObjc(array[index])
                if (objfake.id == objOrg.id) {
                    return false
                }
            }
            return true
        },
        checkPresent(num) {
            let array = this.data.presenters
            array = array.filter(this.thisPresent)
            this.presenter = array
            array = array[0].timer

            for (let index = 0; index < array.length; index++) {
                if (array[index] == num.id) {
                    return true
                }
            }
            return false
        },
        thisPresent(num) {
            let xid = this.vpresenter;
            return num.id == xid;
        },
        restRows(num) {
            let xid = this.data.id;
            return num.id != xid;
        },
        restData(num) {
            let xid = this.data.id;
            return num != xid;
        },
        restCards(num) {
            let xid = this.data.id;
            return num.id != xid;
        },
        async checkedcard() {
            let check = this.vleader
            let data = this.data
            if (this.vtime != null) {
                if (check) {
                    this.vleader = false
                    this.vtime = null
                    let vro = this.$root.cards.filter(this.restCards)
                    this.$root.cards = vro
                } else {
                    this.vleader = true
                    let arr = {
                        "id": data.id,
                        "title": data.title,
                        "date": this.vdate,
                        "presenter": this.vpresenter,
                        "time": String(this.vtime),
                        "duration": this.data.duration
                    };
                    this.$root.cards.push(arr)
                    this.checknon()
                }
            }
        },
        async Refrshcard() {
            this.$root.cards = [];
            this.vpresenter = null;
            this.vtime = null
            this.vleader = null
            this.ctimes = []
        },
        checkalert() {
            this.checkedcard()
            this.vpresenter = null
            this.vtime = null
            if (this.$root.alert != this.data.id && this.$root.alert != 0) {
                this.$root.alertFake = Math.floor(Math.random() * 100)
                this.rstxdelete()
            }
        },
        checkalert2() {
            this.checkedcard()
            this.vpresenter = null
            this.vtime = null
            if (this.$root.alert != this.data.id && this.$root.alert != 0) {
                this.$root.alertFake = Math.floor(Math.random() * 100)
                this.rstxdelete()
            }
        }
    },
    computed: {
        toHouersSort: () => {
            return this.toHouers.sort((a, b) => a.localeCompare(b))
        }
    },
    watch: {
        '$root.cards': 'checknon',
        vpresenter: 'checkhouers',
        vdate: 'Refrshcard',
        vtime: 'checkedcard',
        '$root.alert': 'checkalert',
        '$root.alertFake': 'checkalert2'
    }
}
</script>