<template>

    <form class="row w-100 py-3 p-0 m-0" @submit.prevent="save">
        <h3 class="col-12 text-end mb-4 my-2">
            الأعدادات العامة
        </h3>
        <div class="alert alert-primary border-0 py-2" v-if="msg == true">
            تم حفظ التغييرات بنجاح
        </div>
        <div class="alert alert-danger border-0 py-2" v-else-if="msg == false">
            خطأ في محاولة حفظ التغييرات
            <ul class="text-end">
                <li v-for="(val, num) in err" :key="num">
                    {{ val[0] }}
                </li>
            </ul>
        </div>
        <div class="mb-3 col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label text-end w-100">
                الاسم
            </label>
            <input type="text" class="form-control placeholder" v-if="$root.loadinger">
            <input type="text" class="form-control" v-model="data.name" v-else>
        </div>
        <div class="mb-3 col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label text-end w-100">
                الايميل
            </label>
            <input type="text" class="form-control placeholder" v-if="$root.loadinger">
            <input type="email" class="form-control" v-model="data.email" v-else>

        </div>
        <div class="mb-3 col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label text-end w-100">
                رقم الجوال
            </label>
            <input type="text" class="form-control placeholder" v-if="$root.loadinger">
            <input type="text" class="form-control" v-model="data.phone" v-else>

        </div>
        <button class="btn btn-dark w-100" v-if="button">
            حفظ التغييرات
        </button>
        <button class="btn btn-dark disabled placeholder w-100" aria-disabled="true" v-else>
            جاري الحفظ ...
        </button>
    </form>
</template>

<script>
import axios from "axios";

export default {
    name: 'AuthView',
    data() {
        return {
            msg: null,
            err: [],
            button: true,
            data: [],
        }
    },
    methods: {
        async run() {
            this.$root.loadinger = true
            let token = localStorage.getItem("api-auth");
            if (token != '' && token) {
                await axios.get(this.$root.url + 'api/auth', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                    .then(async (response) => {
                        this.data = response.data
                        this.$root.loading()
                    })
                    .catch(() => {
                        this.$router.push("/")
                    })
            }
        },
        async save() {
            this.button = false
            let token = localStorage.getItem("api-auth");
            await axios
                .post(this.$root.url + "api/auth/save", {
                    name: this.data.name,
                    email: this.data.email,
                    phone: this.data.phone,
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then(async (response) => {
                    if (response.data.status) {
                        this.msg = true
                        this.button = true
                    } else {
                        this.msg = false
                        this.err = response.data.errors
                        this.button = true
                    }
                });
        }
    },
    mounted() {
            this.run();
        }
    }
</script>