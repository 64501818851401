<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header border-bottom py-1">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">
                <img src="@/assets/profile.webp" alt="">
                <text class="me-3" v-if="$root.status">
                    {{ $root.auth.name }}
                </text>
                <text class="me-3" v-else>
                    البروفايل
                </text>
            </h5>
            <button type="button" class="btn me-auto ms-0" data-bs-dismiss="offcanvas" aria-label="Close">
                <img src="@/assets/image.webp" alt="">
            </button>
        </div>
        <div class="offcanvas-body">
            <ul class="list-inline pe-0" v-if="$root.status">
                <li class="border-bottom">
                    <button class="btn w-100 text-end" @click="home">
                        <i class="bi bi-house ms-2"></i>
                        الرئيسية
                    </button>
                </li>
                <li class="border-bottom">
                    <button class="btn w-100 text-end" @click="profile">
                        <i class="bi bi-person-gear ms-2"></i>
                        الاعدادت الشخصية
                    </button>
                </li>
                <li class="border-bottom">
                    <button class="btn w-100 text-end" @click="thanks">
                        <i class="bi bi-bookmarks ms-2"></i>
                        الحجوزات
                    </button>
                </li>
                <li class="border-bottom">
                    <button class="btn w-100 text-end" @click="uot">
                        <i class="bi bi-person-x ms-2"></i>
                        تسجيل الخروج
                    </button>
                </li>
            </ul>
            <ul class="list-inline pe-0" v-else>
                <li class="border-bottom">
                    <button class="btn w-100 text-end" @click="home">
                        <i class="bi bi-house ms-2"></i>
                        الرئيسية
                    </button>
                </li>
                <li class="border-bottom">
                    <button class="btn w-100 text-end" data-bs-toggle="modal" data-bs-target="#authBackdrop">
                        <i class="bi bi-person ms-2"></i>
                        تسجيل الدخول
                    </button>
                </li>
            </ul>

            <table class="table table-striped table-hover border my-2">
                <thead>
                    <tr>
                        <th scope="col">الأيام</th>
                        <th scope="col">أوقات العمل</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, int) in dayNames" :key="int">
                        <th scope="row">
                            {{ val }}
                        </th>
                        <td>{{ filtter(val) }}</td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <Social class="my-2" />
        </div>
    </div>
    <ModalAuth />
</template>

<script>
import $ from 'jquery'
import ModalAuth from "@/components/ModalAuth.vue";
import days from '@/assets/days.json'
import Social from '@/components/Social.vue'
export default {
    components: {
        ModalAuth, Social
    },
    data() {
        return {
            dayTimes: days,
            dayNames: ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "جمعة", "السبت"]
        }
    },
    methods: {
        home() {
            $('[aria-label=Close]').click();
            this.$router.push("/");
        },
        profile() {
            $('[aria-label=Close]').click();
            this.$router.push("/profile");
        },
        thanks() {
            $('[aria-label=Close]').click();
            this.$router.push("/thanks");
        },
        uot() {
            localStorage.clear();
            this.$root.status = false
            $('[aria-label=Close]').click();
            this.$router.push("/");
        },
        filtter(day) {
            let days = this.$root.config.days
            let start = Number(this.$root.config.timestart)
            let end = Number(this.$root.config.timeend)
            let time = this.dayTimes
            for (let index = 0; index < days.length; index++) {
                if (days[index] == day) {
                    return time[start].p + ' ' + time[start].time + ' - ' + time[end].p + ' ' + time[end].time
                }
            }
            return 'يوم أجازة'
        }
    }
}
</script>