<template>
    <div>
        <header class="py-3 mb-4">
            <div class="container d-flex flex-wrap justify-content-center">
                <router-link to="/"
                    class="align-items-center d-flex link-body-emphasis mb-0 mb-md-0 ms-md-auto text-decoration-none">
                    <span class="placeholder" style="width: 150px;height: 50px;" v-if="$root.loadinger"></span>
                    <span class="fs-4" v-else>
                        <img :src="!$root.config.logo ? '/assets/logo.png' : $root.config.logo" height="50"> 
                    </span>
                </router-link>
                <span class="burger me-auto" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><span></span></span>
            </div>
        </header>
    </div>
</template>

<script>
export default {
    name: 'HeaderBar'
}
</script>