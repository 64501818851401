import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Select from '../components/Select.vue'
import AuthView from '../views/AuthView.vue'
import Thanks from '../views/Thanks.vue'
import Rest from '../views/Rest.vue'
import Done from '../views/Done.vue'
import Cancel from '../views/Cancel.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/rest',
    name: 'rest',
    component: Rest
  },
  {
    path: '/profile',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/booking',
    name: 'select',
    component: Select
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: Thanks
  },
  {
    path: '/done',
    name: 'done',
    component: Done
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: Cancel
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
