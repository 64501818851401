<template>
    <div class="text-center d-flex justify-content-center" v-if="$root.config.socail">
        <div v-for="(val, int) in $root.config.socail" :key="int">
            <a :href="val.url" :class="'btn rounded-4 ms-1 bg-gradient ' + color[int]" v-if="val.val == 1">
                <i :class="'bi bi-' + icon[int]"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            icon: [
                'telephone-outbound',
                'envelope-at',
                'snapchat',
                'whatsapp',
                'twitter-x',
                'instagram',
                'tiktok'
            ],
            color: [
                'bg-dark text-light',
                'bg-secondary text-light',
                'bg-warning text-dark',
                'bg-success text-light',
                'bg-dark text-light',
                'bg-danger text-light',
                'bg-dark text-light'
            ]
        }
    }
}
</script>