<template>
    <div class="modal fade" id="authBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="authBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content rounded-0">
                <div class="modal-header bg-fars text-light rounded-0">
                    <h1 class="modal-title fs-5" id="authBackdropLabel">
                        الحساب
                    </h1>
                    <button type="button" class="btn-close me-auto ms-0" data-bs-dismiss="modal" aria-label="Close"
                        style="filter: invert(1);"></button>
                </div>
                <div class="modal-body" v-if="cardlogin">
                    <div class="alert alert-primary border-0 py-2" v-if="msg == true">
                        تم تسجيل الدخول بنجاح
                    </div>
                    <div class="alert alert-danger border-0 py-2" v-else-if="msg == false">
                        خطأ في محاولة تسجيل الدخول
                    </div>
                    <form class="text-start" @submit.prevent="login">
                        <div class="mb-3">
                            <label class="form-label">Email address</label>
                            <input type="email" class="form-control" placeholder="name@example.com" required
                                v-model="email">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Password</label>
                            <input type="password" class="form-control" v-model="pass" required>
                        </div>
                        <button class="btn bg-fars w-100" v-if="button">
                            تسجيل الدخول
                        </button>
                        <button class="btn bg-fars disabled placeholder w-100" aria-disabled="true" v-else>
                            جاري تسجيل الدخول
                        </button>
                    </form>
                    <hr>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-dark w-100" @click="btnlogin">
                                إنشاء حساب جديد
                            </button>
                        </div>
                        <div class="col">
                            <a class="btn btn-light w-100" href="/forgot-password">
                                نسيت كلمة المرور؟
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body" v-else>
                    <div class="alert alert-primary border-0 py-2" v-if="create.msg == true">
                        تم إنشاء حساب بنجاح سيتم تسجيل دخولك حالاً
                    </div>
                    <div class="alert alert-danger border-0 py-2" v-else-if="create.msg == false">
                        حدث خطأ في الاتصال حاول مرة آخرى
                        <ul class="text-end">
                            <li v-for="(val, num) in create.err" :key="num">
                                {{ val[0] }}
                            </li>
                        </ul>
                    </div>
                    <form class="text-start" @submit.prevent="createUser">
                        <div class="mb-3">
                            <label class="form-label">Name</label>
                            <input type="text" class="form-control" placeholder="Ahmad" required v-model="create.name">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Email address</label>
                            <input type="email" class="form-control" placeholder="name@example.com" required
                                v-model="create.email">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" placeholder="05xxxxxxxx" required
                                v-model="create.phone">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Password</label>
                            <input type="password" class="form-control" v-model="create.pass" required>
                        </div>
                        <button class="btn btn-dark w-100" v-if="button">
                            إنشاء حساب
                        </button>
                        <button class="btn btn-dark disabled placeholder w-100" aria-disabled="true" v-else>
                            جاري إنشاء حساب وتسجيل الدخول
                        </button>
                        <hr>
                        <div class="row">
                            <div class="col">
                                <button class="btn bg-fars w-100" @click="btnlogin">
                                    تسجيل الدخول
                                </button>
                            </div>
                            <div class="col">
                                <a class="btn btn-light w-100" href="/forgot-password">
                                    نسيت كلمة المرور؟
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';

export default {
    data() {
        return {
            msg: null,
            pass: '',
            email: '',
            button: true,
            cardlogin: true,
            create: {
                msg: null,
                err: [],
                name: '',
                email: '',
                phone: '',
                pass: ''
            }
        }
    },
    methods: {
        run() { },
        async login() {
            this.$root.auth.name = '...'
            this.button = false
            let xemail = this.email
            let xpass = this.pass
            await axios
                .post(this.$root.url + "api/auth/login", {
                    email: xemail,
                    password: xpass
                })
                .then(async (response) => {
                    if (response.data.status == true) {
                        this.msg = null
                        this.$root.auth.name = response.data.name
                        localStorage.setItem("api-auth", response.data.token);
                        $("#authBackdrop .btn-close").click()
                        this.$root.status = true
                        this.button = true
                    }
                    else {
                        this.msg = false
                        this.button = true
                    }
                });
        },
        async createUser() {
            this.button = false
            await axios
                .post(this.$root.url + "api/auth/register", {
                    name: this.create.name,
                    email: this.create.email,
                    phone: this.create.phone,
                    password: this.create.pass
                })
                .then(async (response) => {
                    if (response.data.status) {
                        this.create.msg = true
                        this.button = true
                        localStorage.setItem("api-auth", response.data.token);
                        this.$root.authx();
                        setTimeout(() => {
                            $("#authBackdrop .btn-close").click()
                        }, 1000)
                    } else {

                        this.create.msg = false
                        this.create.err = response.data.errors
                        this.button = true
                    }
                });

        },
        btnlogin() {
            let card = this.cardlogin
            if (card) this.cardlogin = false
            else this.cardlogin = true
        }
    },
    mounted() {
        // this.run()
    },
}
</script>