<template>
  <HeaderBar />
  <main class="container placeholder-glow">
    <router-view />
  </main>
  <Offcanvas />
  <ModalAuth />
  <div id="footerx" class="my-5 py-5"></div>
</template>

<script>
import axios from "axios";
import $ from 'jquery'
import HeaderBar from '@/components/HeaderBar.vue'
import Offcanvas from '@/components/Offcanvas.vue'
import ModalAuth from '@/components/ModalAuth.vue'

export default {
  name: 'App',
  components: {
    HeaderBar, Offcanvas, ModalAuth
  },
  data() {
    return {
      url: '/',
      editor: 0,
      price: 0,
      tester: 0,
      alert: 0,
      alertcheck: [],
      alertFake: 0,
      loadinger: true,
      vleader: false,
      cselect: [],
      cards: [],
      rows: [],
      data: {
        maps: [],
        tags: [],
        services: []
      },
      select: {
        map: '',
        tag: ''
      },
      auth: [],
      status: false,
      config: {
        logo: '',
        email: '',
        phone: '',
        datepickers: 0,
        pickernumber: 0,
        timestart: 7,
        timeend: 26,
        setmap: 1,
        setsearch: 1,
        days: [],
        socail: [],
        copyright: '',
        copyimage: ''
      }
    }
  },
  methods: {
    async run() {
      this.auth.name = ''
      this.loadinger = true
      await axios
        .get(this.url + "api/maps")
        .then(async (response) => {
          this.config = response.data;
          this.data.maps = response.data.data;
          this.data.tags = response.data.data[0].tags;
          this.select.map = response.data.data[0];
          this.select.tag = response.data.data[0].tags[0];
          this.authx()
          this.loading()
          $('div#footerx').load(this.url + 'footer.html')
          this.$router.push("/rest")
        })
        .catch(() => {
          // this.$router.push({name: 'Error', path: "/error"})
        });
    },
    async authx() {
      let token = localStorage.getItem("api-auth");
      if (token != '' && token) {
        axios.get(this.url + 'api/auth/check', {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .then(async (response) => {
            this.status = response.data.status
            this.auth.name = response.data.name
          })
      }
    },
    loading() {
      setTimeout(() => {
        this.loadinger = false
      }, 1000)
    }
  },
  mounted() {
    this.run();
  },
  computed: {
    xprice() {
      var value = (this.price).toLocaleString(
        undefined,
        { minimumFractionDigits: 2 }
      );
      return value;
    }
  },
  watch: {
    'auth': 'authx',
  }
}
</script>