<template>
  <div class="home">
    <h1 class="title_classic placeholder d-block" style="width: 126px;height: 26px;" v-if="$root.loadinger">
    </h1>
    <h1 class="title_classic" v-else>
      {{ $root.select.map.title }}
      <!-- <button class="float-start btn me-auto" style="color: white;background-color: var(--sun);">
        <i class="bi bi-geo-alt fw-bloder"></i>
      </button> -->
    </h1>
    <Services class="mb-5" />
  </div>
  <FooterBar />
</template>

<script>
import FooterBar from '@/components/FooterBar.vue'
import Services from '@/components/Services.vue'
export default {
  name: 'HomeView',
  components: {
    Services, FooterBar
  },
  methods: {
    async run() {
      this.$root.loadinger = true
      this.$root.loading()
    }
  },
  mounted() {
    this.run();
  }
}
</script>