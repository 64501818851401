<template>
    <div v-if="!$root.loadinger">
        <carousel v-bind="settings" :breakpoints="breakpoints" :dir="'rtl'" class="list-group-checkable">
            <slide v-for="(v, i) in times" :key="i">
                <input class="list-group-item-check pe-none" type="radio" name="listGroupCheckableRadios"
                    :id="'listGroupCheckableRadios' + i" :value="v.date" v-model="vdate">
                <label class="list-group-item rounded-3 py-3 widthover border border-2"
                    :for="'listGroupCheckableRadios' + i">
                    {{ v.month }}
                    <span class="d-block fs-4">{{ v.day }}</span>
                    <span class="d-block small opacity-50">{{ v.nameday }}</span>
                </label>
            </slide>

            <template #addons>
                <navigation />
                <!-- <pagination /> -->
            </template>
        </carousel>
        <hr class="mb-2">
    </div>
    <div class="blocks mb-5 pb-5">
        <div v-if="$root.alertcheck.length != 0">
            <div v-for="(val, int) in $root.alertcheck" :key="int">
                <div class="alert alert-info">
                    <i class="bi bi-calendar2-check"></i>
                    تم حجز موعد
                    <b>{{ val.title }}</b>
                    بنجاح
                </div>
            </div>
        </div>
        <div class="list-group-radio border-0">
            <ListSelect v-for="(vax, num) in $root.rows" :key="num" :data="vax" :vdate="vdate" :ndate="ndate" />
        </div>
    </div>

    <div class="overlayPanel">
        <div class="container">
            <div class="align-items-center row" v-if="$root.loadinger">
                <div class="ServicesSummary-btn col-auto d-flex col">
                    <div class="d-flex flex-column">
                        <p class="ServicesSummary_total mb-0 placeholder d-block mb-2" style="width: 56px;"></p>
                        <div class="d-flex flex-column flex-md-row">
                            <p class="services-summary-total-amount mb-0 fw-bold fs-5 placeholder" style="width: 76px;">
                            </p>
                        </div>
                    </div>
                </div>
                <div class="ServicesSummary-btn ar-mode col text-start">
                    <button type="button" disabled class="undefined btn-dark btn btn-primary placeholder"
                        style="width: 86px;">
                    </button>
                </div>
            </div>
            <div class="align-items-center row" v-else>
                <div class="ServicesSummary-btn col-auto d-flex col">
                    <div class="d-flex flex-column">
                        <p class="ServicesSummary_total mb-0">المجموع:</p>
                        <div class="d-flex flex-column flex-md-row">
                            <p class="services-summary-total-amount mb-0 fw-bold fs-5">{{ $root.xprice }} ريال</p>
                        </div>
                    </div>
                </div>
                <div class="ServicesSummary-btn ar-mode col text-start">
                    <button class="undefined btn-dark btn btn-primary" disabled v-if="!$root.vleader">
                        تأكيد الحجز
                    </button>
                    <div v-else>
                        <button class="undefined btn-dark btn btn-primary" v-if="!$root.status" data-bs-toggle="modal"
                            data-bs-target="#authBackdrop">
                            تأكيد الحجز
                        </button>
                        <button class="undefined btn-dark btn btn-primary" v-else @click="tikat">
                            تأكيد الحجز
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalAuth />
</template>

<script>
import axios from "axios";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import ListSelect from '@/components/ListSelect.vue'
import ModalAuth from '@/components/ModalAuth.vue'
import $ from 'jquery'

export default {
    components: {
        ListSelect,
        ModalAuth,
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            data: [],
            vdate: null,
            ndate: null,
            loadinger: false,
            times: [],
            settings: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            breakpoints: {
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 8,
                    snapAlign: 'center',
                },
            },
        }
    },
    methods: {
        async run() {
            let vid = this.$root.rows[0].id
            this.$root.loadinger = true
            let xdate = this.addDays(new Date, 0)
            let xdayle = (xdate.getDate() < 10 ? '0' + xdate.getDate() : xdate.getDate())
            let xmothle = ((xdate.getMonth() + 1) < 10 ? '0' + (xdate.getMonth() + 1) : (xdate.getMonth() + 1))
            let xtime = (xdayle + '-' + xmothle + '-' + xdate.getFullYear())
            this.ndate = xtime
            $("html, body").animate({ scrollTop: 0 }, "slow");
            await axios
                .get(this.$root.url + "api/services/" + vid)
                .then(async (response) => {
                    this.data = response.data.data[0]
                    this.$root.loadinger = false
                })
            let dayNames = ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "جمعة", "السبت"];
            let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            for (let index = 0; index < 30; index++) {
                let date = this.addDays(new Date, index)
                let dayle = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
                let mothle = ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1))
                let time = (dayle + '-' + mothle + '-' + date.getFullYear())
                let arr = {
                    date: time,
                    month: month[date.getMonth()],
                    day: date.getDate(),
                    nameday: dayNames[date.getDay()]
                }
                if (this.checkday(dayNames[date.getDay()])) {
                    this.times.push(arr)
                    this.vdate = this.times[0].date
                }
            }
        },
        addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },
        runway() {
            let sel = this.$root.cselect
            if (sel.length == 0) {
                this.$router.push('/')
            }
        },
        async tikat() {
            this.button = true
            this.$root.loadinger = true
            let date = this.vdate
            let token = localStorage.getItem("api-auth");
            let rows = this.$root.cards;
            this.$root.alertcheck = []
            let cardx = 0
            this.$root.alert = 0
            for (let index = 0; index < rows.length; index++) {
                await axios.
                    post(this.$root.url + 'api/datepickers', {
                        service: rows[index].id,
                        presenter: rows[index].presenter,
                        date: date,
                        time: rows[index].time
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    }).then((response) => {
                        if (response.data.status == true) {
                            this.$root.alertcheck.push({
                                'title': rows[index].title
                            });
                            cardx++
                        }
                        else {
                            this.$root.alert = rows[index].id
                            this.$root.loadinger = false
                            this.button = false
                        }
                    })
                    .catch(() => {
                        this.$root.loadinger = false
                        this.button = false
                    })
            }
            if (rows.length == cardx) {
                this.$router.push("/thanks")
            }
        },
        checkday(val) {
            let days = this.$root.config.days
            for (let index = 0; index < days.length; index++) {
                if (days[index] == val) {
                    return true
                }
            }
            return false
        }
    },
    mounted() {
        if (this.$root.rows.length == 0) {
            this.$router.push("/")
        } else {
            this.run()
        }
    },
    watch: {
        '$root.cselect': 'runway'
    }
}
</script>