import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import '@/assets/style.css'

// console.warn = () => {};
// console.debug = () => {};
// console.log = () => {};
// console.error = () => {};
// console.info = () => {};
createApp(App).use(router).mount('#app')