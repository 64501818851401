<template>
    <nav class="navbar rscrolling positionsticky border-bottom overflow-x-scroll" style="background-color: white;">
        <ul class="nav nav-pills" style="width: max-content;" v-if="$root.loadinger">
            <li class="nav-item" v-for="int in 6" :key="int" style="height: max-content;width: max-content;">
                <button class="nav-link btn shadow-sm rounded-5 mx-1 text-dark placeholder" style="width: 86px">
                </button>
            </li>
        </ul>
        <ul class="nav nav-pills" style="width: max-content;" v-else>
            <li class="nav-item" v-for="(val, int) in tags" :key="int" style="height: max-content;width: max-content;">
                <button :class="(int ? '' : 'active ') + 'nav-link btnxr btn shadow-sm rounded-5 mx-1 text-dark'"
                    :id="'#appa' + int + '-btn'" @click="btnClick('appa' + int)">
                    {{ val.title }}
                </button>
            </li>
        </ul>
    </nav>
    <div class="blocks mb-5 pb-5" v-if="$root.loadinger">
        <section class="block" v-for="int in 6" :key="int" :id="'appa' + int">
            <div class="list-group-radio border-0">
                <ListGroup v-for="num in 9" :key="num" :data="[]" />
            </div>
        </section>
    </div>
    <div class="blocks mb-5 pb-5" v-else>
        <section class="section block" v-for="(val, int) in tags" :key="int" :id="'appa' + int">
            <div class="card mt-2 p-2 py-4 rounded-0 bg-body-tertiary border-0 mb-0 list-group-item">
                <b class="fs-6 text-color">
                    <i class="bi bi-tags ms-2"></i> {{ val.title }}
                </b>
            </div>
            <div class="list-group-radio border-0">
                <ListGroup v-for="(vax, num) in val.rows" :key="num" :data="vax" />
            </div>
        </section>
    </div>
</template>

<script>
import ListGroup from '@/components/ListGroup.vue'
import $ from 'jquery'
export default {
    name: 'Services',
    components: {
        ListGroup
    },
    data() {
        return {
            map: 'أختر الفرع',
            radio: [],
            tags: [],
            maps: [],
            search: ''
        }
    },
    methods: {
        async run() {
            this.$root.alert = 0
            this.$root.alertcheck = []
            this.$root.cards = [];
            setTimeout(() => {
                this.radio = this.$root.cselect
                this.map = this.$root.select.map;
                this.maps = this.$root.data.maps
                this.tags = this.$root.data.tags
                if (this.$route.name == 'home') {
                    setTimeout(() => {
                        if (this.tags.length) {
                            this.section()
                        }
                    }, 500)
                }
            }, 500)
        },
        async getradio(val) {
            this.$router.push({ name: 'page', params: { id: val } })
        },
        btnClick(val) {
            $('html, body').animate({
                scrollTop: $("#" + val).offset().top
            }, 100);
        },
        section() {
            if (this.$route.name == 'home') {
                var section = document.querySelectorAll(".section");
                var sections = {};
                var i = 0;

                Array.prototype.forEach.call(section, function (e) {
                    sections[e.id] = e.offsetTop;
                });

                window.onscroll = function () {
                    var scrollPosition = document.documentElement.scrollTop ||
                        document.body.scrollTop;
                    for (i in sections) {
                        if (sections[i] <= scrollPosition) {
                            document.querySelector('.btnxr.active').classList.remove("active");
                            document.querySelector('.btnxr[id*=' + i + ']').classList.add("active");
                        }
                    }
                };
            }
        }
    },
    mounted() {
        this.run()
    },
    computed: {
        filteredList() {
            let result = this.tags
            if (!this.search)
                return result

            const filterValue = this.search.toLowerCase()

            const filter = event =>
                event.title.toLowerCase().includes(filterValue)

            return result.filter(filter)
        }
    },
    watch: {
        $root: "run"
    },
}
</script>